import axios from "axios";


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 10000,
})


axiosInstance.interceptors.request.use(function (config) {
    config.headers['Authorization'] =
        localStorage.getItem('access_token')
        ? 'JWT ' + localStorage.getItem('access_token')
        : null
    return config;
    }, function (error) {
        return Promise.reject(error);
    });

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        console.log('error.response', error.response.status)
        if (error.response.status === 401) {
            if (
                originalRequest.url === '/forgot-password/reset' ||
                originalRequest.url === 'login/' ||
                originalRequest.url === 'user/register/' ||
                originalRequest.url === 'forgot-password/'
            ) {
                return Promise.reject(error)
            } else {
                window.location.replace('/login')
            }
        } else {
            return Promise.reject(error)
        }
    }
);

export default axiosInstance;
